<template>
    <button
        type="button"
        @click="$emit('clicked');"
        class="btn border-0 px-5 fw-bold text-white"
        :class="color"
        :disabled="(processing? true: false)">{{ text }} <i v-if="processing" class='fas fa-spinner fa-spin'></i>
    </button>
</template>

<script>

export default {
    props: {
        processing: {default: false},
        text: {default: "Submit"},
        color: {default: "primary-button"}
    },
}
</script>